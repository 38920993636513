<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div
          class="title"
        >沖縄エリアで人気のチケットです。沖縄の魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●各施設のご利用は1回のみです。同施設を2回ご利用できません。
          <br />●パスの使用期間は購入日から
          <span>90日</span>以内です。
          <br />EX：
          <span>7/1</span>ご購入の場合、
          <span>7/1~9/28</span>使用可能です。
          <br />●パスの有効期間：1つ目の施設をご入場から
          <span>7日間</span>以内です。
          <br />EX：
          <span>7/1</span>から利用開始の場合、有効期間は
          <span>7/7</span>までです。
          <br />●利用可能施設数：参画施設の中から、任意の<span>3施設</span>
          <br />●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ
          <span>7日間</span>有効になります。
          <br />●パスの有効期間7日間は購入日から
          <span>90日間</span>以内に限ります。
          <br />EX：
          <span>7/1~9/28</span>使用可能のパスを
          <span>9/27</span>から利用開始された場合、
          有効期間は
          <span>9/27~9/28</span>です。
          <br />●未使用分を払い戻すことはできません。
          <br />●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
          または施設公式サイトでご確認ください。
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt />
            </div>
          </el-image>
        </div>

        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr />
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">
                      {{cItem}}
                      <br />
                    </span>
                    <a
                      :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]"
                      target="_blank"
                      :href="cItem"
                      v-show="msg.title==='公式サイト' && !contentItem.noColor"
                    >
                      {{cItem}}
                      <br />
                    </a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      headImg: require("@/assets/images/hfokinawa/jp/havefun_title_jp.jpg"),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfokinawa/jp/oguri_kimono_salon_JP.jpg'),
          title: ['Oguri Kimono Salon 着物体験1000円クーポン(大人のみ)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒900-0014 沖縄県那覇市松尾2-3-13 2F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['http://oguri-kimono.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。(高校生以上のご利用の場合に限ります。)'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfokinawa/jp/chibai_JP.jpg'),
          title: ['焼肉ホルモン 赤白 食事1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒900-0033 沖縄県那覇市久米２丁目２０−２１ 八木ビル 1階'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yakinikuhorumon_akashiro/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokinawa/jp/SZG_JP.jpg'),
          title: ['沖縄美ら海水族館入館券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒905-0205 沖縄県国頭郡もとぶ町山川1421-5 ( 海風マーケットもとぶ店 )'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://churaumi.okinawa/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokinawa/jp/LQC_JP.jpg'),
          title: ['沖縄琉球村入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '沖縄県国頭郡恩納村山田1130'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.ryukyumura.co.jp/official/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokinawa/jp/YQD_JP.jpg'),
          title: ['おきなわワールド入場券セット (玉泉洞・王国村・ハブ博物公園)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒901-0616　沖縄県南城市玉城字前川1336番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.gyokusendo.co.jp/okinawaworld/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokinawa/jp/zwy_JP.jpg'),
          title: ['東南植物楽園 入場券【昼の部】'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '沖縄県沖縄市知花2146'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.southeast-botanical.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                { cid: 2, text: ['入場可能時間：9:00〜17:00','<br>※夜間入場については、現地で別途入場券を購入してください。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokinawa/jp/kl_JP.jpg'),
          title: ['DINO恐竜PARK やんばる亜熱帯の森 入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '沖繩縣名護市中山1024-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.okashigoten.co.jp/subtropical/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokinawa/jp/bl_JP.jpg'),
          title: ['ナゴパイナップルパーク 入場券 (パイナップルトレインを含む)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒905-0005 沖縄県名護市為又1195'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.nagopine.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※台風などの不可抗力による公共交通機関の運行停止時は営業を中止する場合があります。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokinawa/jp/Neopark_JP.jpg'),
          title: ['ネオパークオキナワ 入場券 + 沖縄軽便鉄道乗車セット'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒905-0012 沖縄県名護市名護4607-41'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.neopark.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※ガイド機関車運行時間：午前10時～午後5時（30分ごとに運行、最終便は午後5時）'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfokinawa/jp/sllr_JP.jpg'),
          title: ['首里琉染 サンゴ染め体験　1000円クーポン (予約必要)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒903-0825 沖縄県那覇市首里山川町 1-54'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.shuri-ryusen.com/']},
                { cid: 2, text: ['予約ページ:'],noColor:true},
                { cid: 3, text: ['ahttps://reserve.shuri-ryusen.com/step1']},
                { cid: 4, text: ['<span style="color:red">※予約際に、支払い方法が店頭でのお支払いの選択肢を選択ください。</span>'] ,noColor:true },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全予約制で営業いたします。ご利用の際は、事前予約が必要になります。事前にご予約の無い場合は、休校している場合もございます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['体験の時間：9:30 / 11:00 / 12:30 / 14:00 / 15:30','<br>※体験には約50分ほどの時間がかかりますので、体験時間の15分前にお越しください。'] },
                { cid: 4, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfokinawa/jp/haidi_JP.jpg'),
          title: ['オルカ号海底散歩　1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '沖縄県那覇市通堂町２−１(那覇港 旅客待合エリア WEST MARINE)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://ja.westmarine.co.jp/info_water.php']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['体験に約50～60分ほどの時間がかかります。'] },
                { cid: 3, text: ['当日乗客が多い場合、安全のために分散して展望室に入場することがあります。'] },
                { cid: 4, text: ['遊覧船は定期的にメンテナンスを行います。メンテナンス期間中は運行を停止します。また、天候や海況は予測できないため（例：大風や波で船が安定しない、海の視界が悪いなど）、当日現地到着後に船に乗ることが適切でないと判断された場合、その都度キャンセルまたは日程変更を選択することができます。'] },
                { cid: 5, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfokinawa/jp/yuyi_JP.jpg'),
          title: ['ちゅら桜 着物/浴衣体験 1000円クーポン (入店指定時間: 11:00)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒900-0015 沖縄県那覇市久茂地1-6-1 ARTビル2-A'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.churasakura.okinawa/#']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['入店指定時間：11:00'] },
                { cid: 3, text: ['入店時に混雑が予想される場合、少々お待ちいただくことがあります。何卒ご了承ください。'] },
                { cid: 4, text: ['当日18:00までにご返却をお願いいたします。遅延の場合、自動的に翌日返却となり、翌日の返却時にはお一人様につき1100円の追加料金がかかります。また、レンタル中は個人の持ち物をお返しできませんので、予めご注意ください。'] },
                { cid: 5, text: ['返却時、レンタル品に修復不可能な汚れ（ペイントなど）、損傷（たばこの焼け跡、縫製箇所以外の損傷、穴など）がある場合、賠償金として5000円を申し受けます。現地でお支払いください。'] },
                { cid: 6, text: ['当日は着替えがしやすいインナーウェアまたはタイトな服装がおすすめです。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfokinawa/jp/heniu_JP.jpg'),
          title: ['沖縄 牛武士1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '中頭郡北谷町美浜34−3 DEPOT CENTRAL 6F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.gyu-samurai.com/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。(以下のセットのご利用の場合に限ります。)',
                '<br>Aセット内容（季節により若干変動あり）<br>前菜2種盛り合わせ、キムチ盛り合わせ、上質な牛タン、厳選上質な牛ヒレ、厳選上質な牛カルビ、県産アコウ豚カルビ、特選サーロインステーキ、内臓3種盛り合わせ、昆布卵スープ、ご飯、デザート、ノンアルコールドリンク1杯',
                '<br>Bセット内容（季節により若干変動あり）<br>前菜3種盛り合わせ、本日のサラダ、キムチ盛り合わせ、上質な牛タン、極上霜降り牛ヒレ、極上霜降り牛カルビ、特選厚切りフィレ、県産アコウ豚カルビ、海鮮3種盛り合わせ、内臓3種盛り合わせ、昆布卵スープ、ご飯、デザート、ノンアルコールドリンク1杯'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['満席のためチケットが使用できないことがあります。予めご了承ください。'] },
                ]
            }
          ]
        },
      ]
    };
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkinawa&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okinawa-1-week-free-pass/jp', '_self')
  }
};
</script>
  <style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>